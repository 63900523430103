import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    TOKEN: null,
    userInfo: null,
    walletInfo: null
};

const menu = createSlice({
    name: "menu",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isLoggedIn = true;
            state.TOKEN = action.payload;
        },
        logout: (state, action) => {
            state.isLoggedIn = false;
            state.TOKEN = action.payload;
        },
        userDetails: (state, action) => {
            state.userInfo = action.payload;
        },
        walletDetails: (state, action) => {
            state.walletInfo = action.payload;
        },
    }
})

export default menu.reducer;

export const {
    login,
    logout,
    userDetails,
    walletDetails
} = menu.actions;