import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    Spinner
} from "reactstrap";
import {
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    UserAvatar,
    PaginationComponent,
    Button,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import CustomToast from "../../../components/toast";
import useApiService from "../../../hooks/apiService";

const UsersListPage = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");
    const [isUsersListLoading, setIsUsersListLoading] = useState(false)
    // Sorting data
    const sortFunc = (params) => {
        let defaultData = data;
        if (params === "asc") {
            let sortedData = defaultData.sort((a, b) => a.ref.localeCompare(b.ref));
            setData([...sortedData]);
        } else if (params === "dsc") {
            let sortedData = defaultData.sort((a, b) => b.ref.localeCompare(a.ref));
            setData([...sortedData]);
        }
    };

    // Changing state value when searching name
    useEffect(() => {
        if (onSearchText !== "") {
            const filteredObject = data.filter((item) => {
                return item?.ref.toLowerCase().includes(onSearchText.toLowerCase());
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText]);

    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleClose = () => {
        setToastMessage(null);
    };

    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);

    // function to toggle details modal
    const handleApiError = (err) => {
        setToastMessage({
            message: err,
            toastType: "error"
        });
    }

    const fetchUsersList = async () => {
        try {
            setIsUsersListLoading(true); // Show loader when fetching data

            const params = {
                page: currentPage,
                limit: itemPerPage,
                order: sort,
                filterName: onSearchText,
            };

            console.log("params", params);

            const { result, success, message, total } = await apiService(
                'GET',
                '/fetch-users-list',
                null,
                params
            );

            console.log("get recharge res", total, result)
            if (success) {
                setData(result);
                setTotalRows(total);
            } else if (result?.length === 0) {
                setData([]);
                handleApiError('No Users Found...');
            } else if (message === 'Failed to authenticate') {
                handleApiError('Failed to authenticate');
                setSessionExpired(true);
                setData([]);
            } else {
                handleApiError(message);
                setData([]);
            }
        } catch (error) {
            console.error('Failed to fetch matches:', error);
            handleApiError(error);
        } finally {
            setIsUsersListLoading(false); // Hide loader after data is fetched or an error occurs
        }
    }


    // Changing state value when searching name
    useEffect(() => {
        fetchUsersList();
    }, [currentPage, itemPerPage, sort, onSearchText]);

    return (
        <React.Fragment>
            <Head title="User List"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                Users Lists
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {totalRows} users.</p>
                            </BlockDes>
                        </BlockHeadContent>

                    </BlockBetween>
                </BlockHead>

                <Block>
                    <DataTable className="card-stretch">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Users List</h5>
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <Button
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                                    <Icon name="setting"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu end className="dropdown-menu-xs">
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Show</span>
                                                        </li>

                                                        <li className={itemPerPage === 10 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setItemPerPage(10);
                                                                }}
                                                            >
                                                                10
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={itemPerPage === 15 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setItemPerPage(15);
                                                                }}
                                                            >
                                                                15
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Order</span>
                                                        </li>
                                                        <li className={sort === "dsc" ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setSortState("dsc");
                                                                    sortFunc("dsc");
                                                                }}
                                                            >
                                                                DESC
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={sort === "asc" ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setSortState("asc");
                                                                    sortFunc("asc");
                                                                }}
                                                            >
                                                                ASC
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                    <div className="search-content">
                                        <Button
                                            onClick={() => {
                                                setSearchText("");
                                                toggle();
                                            }}
                                            className="search-back btn-icon toggle-search"
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Search by Order Id"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody>
                            <DataTableHead>
                                <DataTableRow>
                                    <span className="sub-text">User</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Total Shares</span>
                                </DataTableRow>
                                <DataTableRow size="mb">
                                    <span className="sub-text">Balance</span>
                                </DataTableRow>
                                <DataTableRow size="md">
                                    <span className="sub-text">Created On</span>
                                </DataTableRow>
                            </DataTableHead>
                            {/*Head*/}

                            {isUsersListLoading ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <Spinner
                                            color="primary"
                                            type="grow"
                                        >
                                            Loading...
                                        </Spinner>
                                    </td>
                                </tr>
                            ) : (
                                data.length > 0
                                    ? data.map((item) => {
                                        return (
                                            <DataTableItem key={item.id}>
                                                <DataTableRow>
                                                    <Link to={`${process.env.PUBLIC_URL}/user-details-regular/${item.id}`}>
                                                        <div className="user-card">
                                                            <UserAvatar
                                                                theme={item.avatarBg}
                                                                text={item.name}
                                                                image={item.image}
                                                            ></UserAvatar>
                                                            <div className="user-info">
                                                                <span className="tb-lead">
                                                                    {`${item.name} ${item.mname} ${item.lname}`}
                                                                    <span
                                                                        className={`dot dot-${item.status === "Active"
                                                                            ? "success"
                                                                            : item.status === "Pending"
                                                                                ? "warning"
                                                                                : "danger"
                                                                            } d-md-none ms-1`}
                                                                    ></span>
                                                                </span>
                                                                <span>{item.email}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </DataTableRow>
                                                <DataTableRow size="mb">
                                                    <span className="tb-amount">
                                                        {item?.total_shares} <span className="currency">Runs</span>
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow size="mb">
                                                    <span className="tb-amount">
                                                        {item.wallet} <span className="currency">Runs</span>
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow size="md">
                                                    <span>{item.createdOn}</span>
                                                </DataTableRow>
                                            </DataTableItem>
                                        )
                                    })
                                    :
                                    !isUsersListLoading &&
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            <Spinner
                                                color="primary"
                                                type="grow"
                                            >
                                                No Record Found!
                                            </Spinner>
                                        </td>
                                    </tr>)}
                        </DataTableBody>
                        <div className="card-inner">
                            {data.length > 0 &&
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={totalRows}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            }
                        </div>
                    </DataTable>
                </Block>

            </Content>
            <CustomToast
                open={toastMessage?.toastType ? true : false}
                handleClose={handleClose}
                severity={toastMessage?.toastType}
                message={toastMessage?.message}
            />

        </React.Fragment>
    );
};
export default UsersListPage;
