import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    ModalBody,
    Modal,
    DropdownItem,
    Form,
    Badge,
    Spinner,
    Input,
    FormGroup,
    Label,
} from "reactstrap";
import {
    Button,
    Block,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Icon,
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    DataTableItem,
    PaginationComponent,
} from "../../../components/Component";
import CustomToast from "../../../components/toast";
import useApiService from "../../../hooks/apiService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const LevelsList = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { userInfo } = useSelector((state) => state.menu)
    const navigate = useNavigate()
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");
    const [selectedLevel, setSelectedLevel] = useState("");
    const [isAddShareModalOpen, setIsAddShareModalOpen] = useState(false);
    const [isAddShareLoading, setIsAddShareLoading] = useState(false);
    const [isLevelsLoading, setIsLevelsLoading] = useState(false)
    // New state for form inputs and validation errors
    const [noOfShares, setNoOfShares] = useState("");
    const [sharesPerRuns, setSharesPerRuns] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [upperLimit, setUpperLimit] = useState("");
    const [lowerLimit, setLowerLimit] = useState("");
    const [totalRuns, setTotalRuns] = useState(0);
    // Sorting data
    const sortFunc = (params) => {
        let defaultData = data;
        if (params === "asc") {
            let sortedData = defaultData.sort((a, b) => a.ref.localeCompare(b.ref));
            setData([...sortedData]);
        } else if (params === "dsc") {
            let sortedData = defaultData.sort((a, b) => b.ref.localeCompare(a.ref));
            setData([...sortedData]);
        }
    };

    // Changing state value when searching name
    useEffect(() => {
        if (onSearchText !== "") {
            const filteredObject = data.filter((item) => {
                return item?.ref.toLowerCase().includes(onSearchText.toLowerCase());
            });
            setData([...filteredObject]);
        } else {
            setData([...data]);
        }
    }, [onSearchText]);

    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleClose = () => {
        setToastMessage(null);
    };


    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);

    // function to toggle details modal
    const handleApiError = (err) => {
        setToastMessage({
            message: err,
            toastType: "error"
        });
    }

    const handleAddShares = async (e) => {
        e.preventDefault(); // Prevent default form submission

        // Validate form
        let errors = {};
        if (!noOfShares) errors.noOfShares = "No. of shares is required";
        if (!sharesPerRuns) errors.sharesPerRuns = "Shares per runs is required";
        if (!lowerLimit) errors.lowerLimit = "Lower limit is required";
        if (!upperLimit) errors.upperLimit = "Upper limit is required";

        console.log("sharesPerRuns---",sharesPerRuns)
        // Check if upper limit is greater than lower limit
        if (parseFloat(upperLimit) <= parseFloat(lowerLimit)) {
            errors.upperLimit = "Upper limit must be greater than lower limit";
            errors.lowerLimit = "Lower limit must be less than upper limit";
        }

        setFormErrors(errors);

        // If there are errors, do not proceed
        if (Object.keys(errors).length > 0) return;

        const requestPayload = {
            level_id: selectedLevel?.id,
            no_of_shares: noOfShares,
            shares_per_runs: sharesPerRuns,
            upper_limit: upperLimit,
            lower_limit: lowerLimit,
        };

        try {
            setIsAddShareLoading(true);

            const { result, success, message } = await apiService('POST', '/update-level-shares', requestPayload);

            if (success) {
                setIsAddShareModalOpen(false);
                fetchLevelList();
                setToastMessage({
                    message: "Level Shares Added Successfully!",
                    toastType: "success"
                });

            } else if (message === "Failed to authenticate") {
                setToastMessage({
                    message: "Failed to authenticate. Please login again to continue...",
                    toastType: "error"
                });
                navigate('/');
            } else {
                setToastMessage({
                    message: message,
                    toastType: "error"
                });
            }
        } catch (err) {
            setToastMessage({
                message: err || 'An error occurred',
                toastType: "error"
            });
        } finally {
            setIsAddShareLoading(false);
        }
    }

    const fetchLevelList = async () => {
        try {
            setIsLevelsLoading(true); // Show loader when fetching data

            const params = {
                page: currentPage,
                limit: itemPerPage,
                order: sort,
                filterName: onSearchText,
            };

            console.log("params", params);

            const { result, success, message, total } = await apiService(
                'GET',
                '/fetch-levels-list', // Adjust the endpoint if necessary
                null,
                params
            );

            console.log("get recharge res", total, result)
            if (success) {
                setData(result);
                setTotalRows(total);
            } else if (result?.length === 0) {
                setData([]);
                handleApiError('No Wallet report found...');
            } else if (message === 'Failed to authenticate') {
                handleApiError('Failed to authenticate');
                setSessionExpired(true);
                setData([]);
            } else {
                handleApiError(message);
                setData([]);
            }
        } catch (error) {
            console.error('Failed to fetch matches:', error);
            handleApiError(error);
        } finally {
            setIsLevelsLoading(false); // Hide loader after data is fetched or an error occurs
        }
    }


    // Changing state value when searching name
    useEffect(() => {
        fetchLevelList(); // Fetch data when component mounts or dependencies change
    }, [currentPage, itemPerPage, sort, onSearchText]);

    return (
        <React.Fragment>
            <Head title="Trasaction List - Crypto"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>All Levels List</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {totalRows} levels.</p>
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    <DataTable className="card-stretch">
                        <div className="card-inner">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Levels List</h5>
                                </div>
                                <div className="card-tools me-n1">
                                    <ul className="btn-toolbar gx-1">
                                        <li>
                                            <Button
                                                href="#search"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    toggle();
                                                }}
                                                className="btn-icon search-toggle toggle-search"
                                            >
                                                <Icon name="search"></Icon>
                                            </Button>
                                        </li>
                                        <li className="btn-toolbar-sep"></li>
                                        <li>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                                    <Icon name="setting"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu end className="dropdown-menu-xs">
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Show</span>
                                                        </li>

                                                        <li className={itemPerPage === 10 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setItemPerPage(10);
                                                                }}
                                                            >
                                                                10
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={itemPerPage === 15 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setItemPerPage(15);
                                                                }}
                                                            >
                                                                15
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>Order</span>
                                                        </li>
                                                        <li className={sort === "dsc" ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setSortState("dsc");
                                                                    sortFunc("dsc");
                                                                }}
                                                            >
                                                                DESC
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={sort === "asc" ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setSortState("asc");
                                                                    sortFunc("asc");
                                                                }}
                                                            >
                                                                ASC
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                                    <div className="search-content">
                                        <Button
                                            onClick={() => {
                                                setSearchText("");
                                                toggle();
                                            }}
                                            className="search-back btn-icon toggle-search"
                                        >
                                            <Icon name="arrow-left"></Icon>
                                        </Button>
                                        <input
                                            type="text"
                                            className="border-transparent form-focus-none form-control"
                                            placeholder="Search by Order Id"
                                            value={onSearchText}
                                            onChange={(e) => onFilterChange(e)}
                                        />
                                        <Button className="search-submit btn-icon">
                                            <Icon name="search"></Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTableBody bodyclass="nk-tb-tnx">
                            <DataTableHead>
                                <DataTableRow>
                                    <span>Parent id</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Level Name</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Share</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Share Per Run</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Upper Limit</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Lower Limit</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Created On</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>Action</span>
                                </DataTableRow>
                                <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                            </DataTableHead>

                            {isLevelsLoading ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <Spinner
                                            color="primary"
                                            type="grow"
                                        >
                                            Loading...
                                        </Spinner>
                                    </td>
                                </tr>
                            ) : (
                                data.length > 0
                                    ? data.map((item) => {
                                        return (
                                            <DataTableItem key={item?.id}>
                                                <DataTableRow>
                                                    <div className="nk-tnx-type">
                                                        <div className="nk-tnx-type-text">
                                                            <span className="tb-lead">#{`${item?.tkt_level_id}`}</span>
                                                        </div>
                                                    </div>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="tb-amount">
                                                        {item?.level_name}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="tb-amount">
                                                        {item?.shares || 0}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="tb-amount">
                                                        {item?.shares_value || 0}
                                                    </span>
                                                </DataTableRow>
                                                 <DataTableRow>
                                                    <span className="tb-amount">
                                                        {item?.upper_limit || 0}
                                                    </span>
                                                </DataTableRow>
                                                 <DataTableRow>
                                                    <span className="tb-amount">
                                                        {item?.lower_limit || 0}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="tb-amount">
                                                        {item?.createdOn}
                                                    </span>
                                                </DataTableRow>
                                                <DataTableRow className="nk-tb-col-tools text-end">
                                                    <Button onClick={() => {
                                                        setSelectedLevel(item);
                                                        setIsAddShareModalOpen(true);
                                                    }} color="primary" size="md">
                                                        Add Shares
                                                    </Button>
                                                </DataTableRow>
                                            </DataTableItem>
                                        );
                                    })
                                    : null)}
                        </DataTableBody>
                        <div className="card-inner">
                            {data.length > 0 ? (
                                <PaginationComponent
                                    itemPerPage={itemPerPage}
                                    totalItems={totalRows}
                                    paginate={paginate}
                                    currentPage={currentPage}
                                />
                            ) :
                                !isLevelsLoading && (
                                    <div className="text-center">
                                        <span className="text-silent">No data found</span>
                                    </div>
                                )}
                        </div>
                    </DataTable>
                </Block>

            </Content>

            <Modal
                isOpen={isAddShareModalOpen}
                toggle={() => setIsAddShareModalOpen(true)}
                className="modal-dialog-centered"
                size="md"
            >
                <a
                    href="#close"
                    onClick={() => setIsAddShareModalOpen(false)}
                    className="close"
                >
                    <Icon name="cross-sm" />
                </a>
                <ModalBody className="modal-body-lg">
                    <BlockHead className="nk-block-head-xs text-center">
                        <BlockTitle tag="h5">Enter the number of shares and shares per runs.</BlockTitle>
                        <div className="nk-block-text">
                        </div>
                    </BlockHead>
                    <Block>
                        <Form onSubmit={handleAddShares}>
                            <FormGroup>
                                <Label for="noOfShares">No. of Shares</Label>
                                <Input
                                    type="number"
                                    id="noOfShares"
                                    value={noOfShares}
                                    onChange={(e) => setNoOfShares(e.target.value)}
                                    invalid={!!formErrors.noOfShares}
                                />
                                <div className="invalid-feedback">{formErrors.noOfShares}</div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="sharesPerRuns">Runs Per Share</Label>
                                <Input
                                    type="number"
                                    id="sharesPerRuns"
                                    value={sharesPerRuns}
                                    onChange={(e) => {
                                        setSharesPerRuns(e.target.value);
                                        setTotalRuns((Number(noOfShares) * Number(e.target.value)));
                                    }}
                                    invalid={!!formErrors.sharesPerRuns}
                                />
                                <div className="form-note-group">
                                    <span className="buysell-min form-note-alt"></span>
                                    <span className="buysell-rate form-note-alt">Total Runs = {totalRuns} Runs</span>
                                </div>
                                <div className="invalid-feedback">{formErrors.sharesPerRuns}</div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="lowerLimit">Lower Limit</Label>
                                <Input
                                    type="number"
                                    id="lowerLimit"
                                    value={lowerLimit}
                                    onChange={(e) => setLowerLimit(e.target.value)}
                                    invalid={!!formErrors.lowerLimit}
                                />
                                <div className="invalid-feedback">{formErrors.lowerLimit}</div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="upperLimit">Upper Limit</Label>
                                <Input
                                    type="number"
                                    id="upperLimit"
                                    value={upperLimit}
                                    onChange={(e) => setUpperLimit(e.target.value)}
                                    invalid={!!formErrors.upperLimit}
                                />
                                <div className="invalid-feedback">{formErrors.upperLimit}</div>
                            </FormGroup>
                            <Button type="submit" color="primary" disabled={isAddShareLoading}>
                                {isAddShareLoading ? <Spinner size="sm" /> : "Add Shares"}
                            </Button>
                        </Form>

                    </Block>
                </ModalBody>
            </Modal>

            <CustomToast
                open={toastMessage?.toastType ? true : false}
                handleClose={handleClose}
                severity={toastMessage?.toastType}
                message={toastMessage?.message}
            />
        </React.Fragment>
    );
};

export default LevelsList;
