import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
    Input,
    Form,
    Spinner,
    Label,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    Button,
    FormGroup,
    FormFeedback
} from "reactstrap";
import { Block, BlockHead, BlockTitle, Icon } from "../../../components/Component";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useApiService from "../../../hooks/apiService";
import CustomToast from "../../../components/toast";
import { UseAdminWalletBalanceUpdate } from "../../../hooks/account-balance";

const AddTeamShare = () => {
    const { apiService, toastMessage, setToastMessage } = useApiService();
    const { updateAdminWalletBalance } = UseAdminWalletBalanceUpdate()

    const { userInfo, walletInfo } = useSelector((state) => state.menu);
    const location = useLocation();
    console.log("locationsss", location);
    const [teamDetails] = useState(location?.state)
    const [teamShares, setTeamShares] = useState("");
    const [runs, setRuns] = useState("");
    const [error, setError] = useState("");
    const [upperLimit, setUpperLimit] = useState("");
    const [lowerLimit, setLowerLimit] = useState("");
    const [isAddSharesLoading, setIsAddSharesLoading] = useState(false)
    const [modal, setModal] = useState({
        confirm: false,
        buyCoin: false,
    });
    const [runPerShare] = useState((Number(teamDetails?.shares_value) / Number(teamDetails?.total_shares)) || 0)

    const handleClose = () => {
        setToastMessage(null);
    };


    // Handler for the runs input field
    const handleSharesChange = (e) => {
        const value = e.target.value;
        setTeamShares(value);

        // Update the amount automatically based on runs input
        if (value) {
            setRuns(value * runPerShare);
        } else {
            setRuns("");
        }

        // Clear any previous error when the user types
        setError("");
    };


    // Validation function to ensure runs input is not empty
    const validateForm = () => {
        // if (upperLimit.trim() === "" || lowerLimit.trim() === "") {
        //     setError("Please enter both upper and lower limits.");
        //     return false;
        // }

        if (teamShares.trim() === "") {
            setError("Please enter the number of runs you want to buy.");
            return false;
        }
        
        return true;
    };


    // function to close the form modal
    const onFormCancel = () => {
        setModal({ confirm: false, buyCoin: false });
    };


    const handleContinueToBuy = (e) => {
        e.preventDefault();
        if (validateForm()) {
            if(walletInfo?.wallet > runs){
                setModal({ buyCoin: true })
            }else{
                setToastMessage({
                    message: "Your wallet not have the enough runs to buy. Please add runs to continue..",
                    toastType: "error"
                })
            }
        }
    }

    const checkWalletBalance = () => {
        if (runs <= walletInfo?.wallet) {
            return true
        } else {
            return false;
        }
    }

    const handleConfirmOrder = async (e) => {
        if (checkWalletBalance()) {
            const requestPayload = {
                team_shares: teamShares,
                runs: runs,
                // upper_limit: upperLimit,
                // lower_limit: lowerLimit,
                team_id: teamDetails?.team_id,
                admin_id: userInfo?.id,
            };

            console.log("requestPayload", requestPayload);

            try {
                setIsAddSharesLoading(true);

                const { result, success, message } = await apiService('POST', '/add-team-shares', requestPayload);

                console.log("validate pin response",message, result, success);

                if (success) {
                    await updateAdminWalletBalance();
                    setModal({
                        buyCoin: false,
                        confirm: true
                    })
                    setToastMessage({
                        message: "Team Shares Added Successfully!",
                        toastType: "success"
                    });

                } else if (message === "Failed to authenticate") {
                    setToastMessage({
                        message: "Failed to authenticate. Please login again to continue...",
                        toastType: "error"
                    });
                    navigate('/');
                } else {
                    setToastMessage({
                        message: message,
                        toastType: "error"
                    });
                }
            } catch (err) {
                console.log("errr",err)
                setToastMessage({
                    message: err || 'An error occurred',
                    toastType: "error"
                });
            } finally {
                setIsAddSharesLoading(false);
            }
        } else {
            handleApiError("Requested runs are more that your wallet balance. Please recharge your wallet and try again.");
        }

    }

    return (
        <React.Fragment>
            <Head title="Crypto Dashboard"></Head>
            <Content>
                <div className="buysell wide-xs m-auto">
                    <div className="buysell-nav text-center">
                        <ul className="nk-nav nav nav-tabs nav-tabs-s2">
                            <li className="nav-item">
                                <a className="nav-link active" href="#">
                                    Add Shares
                                </a>
                            </li>
                            {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  Sell Coin
                </a>
              </li> */}
                        </ul>
                    </div>
                    <div className="buysell-title text-center mb-5 mt-5">
                        <h2 className="title">Are you want to Add!</h2>
                    </div>
                    <div className="buysell-block">
                        <Form className="buysell-form">

                            <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="buysell-amount">
                                        Enter Share that you want to add
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="number"
                                        id="runsInput"
                                        value={teamShares}
                                        className="form-control form-control-lg form-control-number"
                                        onChange={handleSharesChange}
                                        invalid={error ? true : false}
                                        placeholder="Enter number of Shares"
                                    />
                                </div>
                                {error && <FormFeedback>{error}</FormFeedback>}
                            </div>

                            <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="buysell-amount">
                                        Runs
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="text"
                                        className="form-control form-control-lg form-control-number"
                                        id="buysell-amount"
                                        name="bs-amount"
                                        placeholder={`1 Share = ${runPerShare} Runs`}
                                        value={runs}
                                        readOnly
                                    />
                                </div>
                                <div className="form-note-group">
                                    <span className="buysell-min form-note-alt"></span>
                                    <span className="buysell-rate form-note-alt">1 share = {runPerShare} Runs</span>
                                </div>
                            </div>

                            {/* <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="upper-limit">
                                        Upper Limit
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="number"
                                        id="upper-limit"
                                        value={upperLimit}
                                        className="form-control form-control-lg form-control-number"
                                        onChange={(e) => setUpperLimit(e.target.value)}
                                        placeholder="Enter Upper Limit"
                                    />
                                </div>
                            </div>

                            <div className="form-group buysell-field">
                                <div className="form-label-group">
                                    <Label className="form-label" for="lower-limit">
                                        Lower Limit
                                    </Label>
                                </div>
                                <div className="form-control-group">
                                    <Input
                                        type="number"
                                        id="lower-limit"
                                        value={lowerLimit}
                                        className="form-control form-control-lg form-control-number"
                                        onChange={(e) => setLowerLimit(e.target.value)}
                                        placeholder="Enter Lower Limit"
                                    />
                                </div>
                            </div> */}

                            <div className="buysell-field form-action">
                                <Button
                                    color="primary"
                                    className="btn btn-lg btn-block btn-primary"
                                    onClick={handleContinueToBuy}
                                >
                                    Continue to Add
                                </Button>
                            </div>

                        </Form>
                    </div>
                </div>
                <Modal
                    isOpen={modal.buyCoin}
                    toggle={() => setModal({ buyCoin: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <a
                        href="#close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <ModalBody className="modal-body-lg">
                        <BlockHead className="nk-block-head-xs text-center">
                            <BlockTitle tag="h5">Confirm Shares</BlockTitle>
                            <div className="nk-block-text">
                                <div className="caption-text">
                                    You are about to get <strong>{teamShares}</strong> Shares for <strong>{runs}</strong> Runs*
                                </div>
                                <span className="sub-text-sm">Share rate: 1 Share = {teamDetails?.share_per_run} Runs</span>
                            </div>
                        </BlockHead>
                        <Block>
                            <div className="buysell-field form-action text-center">
                                <div>
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-lg"
                                        onClick={handleConfirmOrder}
                                    >
                                        {isAddSharesLoading ? <Spinner size="sm" color="light" /> : "Add Shares"}
                                    </Button>
                                </div>
                                <div className="pt-3">
                                    <a
                                        href="#"
                                        className="link link-danger"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                    >
                                        Cancel
                                    </a>
                                </div>
                            </div>
                        </Block>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={modal.confirm}
                    toggle={() => setModal({ confirm: false })}
                    className="modal-dialog-centered"
                    size="md"
                >
                    <a
                        href="#close"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm" />
                    </a>
                    <ModalBody className="modal-body-lg text-center">
                        <div className="nk-modal">
                            <Icon name="check" className="nk-modal-icon icon-circle icon-circle-xxl bg-success" />
                            <h4 className="nk-modal-title">Successfully sent payment!</h4>
                            <div className="nk-modal-text">
                                <p className="caption-text">
                                    Successfully added <strong>{teamShares}</strong> Shares for <strong>{runs} Runs</strong> USD. It will updated to your wallet account once approved by admin.
                                </p>
                            </div>
                            <div className="nk-modal-action-lg">
                                <ul className="btn-group gx-4">
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/team-share-wallet `} className="btn btn-lg btn-mw btn-primary">
                                            Return
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Content>
            <CustomToast
                open={toastMessage?.toastType ? true : false}
                handleClose={handleClose}
                severity={toastMessage?.toastType}
                message={toastMessage?.message}
            />
        </React.Fragment>
    );
};

export default AddTeamShare;
