import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,

  ModalBody,
  Modal,
  DropdownItem,
  Form,
  Badge,
  Spinner
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  TooltipComponent,
  Row,
  Col,
  OverlineTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  RSelect,
} from "../../../components/Component";
import CustomToast from "../../../components/toast";
import useApiService from "../../../hooks/apiService";
import { useSelector } from "react-redux";
import { UseAdminWalletBalanceUpdate } from "../../../hooks/account-balance";
const PendingWalletRequestList = () => {
  const { apiService, toastMessage, setToastMessage } = useApiService();
  const { updateAdminWalletBalance } = UseAdminWalletBalanceUpdate()
  const { walletInfo, userInfo } = useSelector((state) => state.menu)
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [isWalletReportLoading, setIsWalletReportLoading] = useState(false)
  const [isApproveLoading, setIsApproveLoading] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)
  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.ref.localeCompare(b.ref));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.ref.localeCompare(a.ref));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = data.filter((item) => {
        return item.ref.toLowerCase().includes(onSearchText.toLowerCase());
      });
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }
  }, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  }

  const handleClose = () => {
    setToastMessage(null);
  };


  // function to change to approve property for an item
  const onApproveClick = (item) => {
    setIsModalOpen(true)
    setSelectedRequest(item)
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // function to toggle details modal
  const handleApiError = (err) => {
    setToastMessage({
      message: err,
      toastType: "error"
    });
  }

  const fetchPendingWalletReport = async () => {
    try {
      setIsWalletReportLoading(true); // Show loader when fetching data

      const params = {
        page: currentPage,
        limit: itemPerPage,
        order: sort,
        filterName: onSearchText,
        order_status: 'pending'
      };

      console.log("params", params);

      const { result, success, message, total } = await apiService(
        'GET',
        '/get-admin-wallet-recharge-list', // Adjust the endpoint if necessary
        null,
        params
      );

      console.log("get recharge res", total, result)
      if (success) {
        setData(result);
        setTotalRows(total);
      } else if (result?.length === 0) {
        setData([]);
        handleApiError('No Wallet report found...');
      } else if (message === 'Failed to authenticate') {
        handleApiError('Failed to authenticate');
        setSessionExpired(true);
        setData([]);
      } else {
        handleApiError(message);
        setData([]);
      }
    } catch (error) {
      console.error('Failed to fetch matches:', error);
      handleApiError(error);
    } finally {
      setIsWalletReportLoading(false); // Hide loader after data is fetched or an error occurs
    }
  }

  const checkWalletBalance = () => {
    if (selectedRequest?.no_runs < walletInfo?.wallet) {
      return true
    } else {
      return false;
    }
  }

  const handleApprove = async () => {
    if (checkWalletBalance()) {
      try {
        setIsApproveLoading(true); // Show loader when fetching data

        const requestedPayload = {
          requested_id: selectedRequest?.id,
          requested_runs: selectedRequest?.no_runs,
          member_id: selectedRequest?.customer_wallet_id,
          admin_id: userInfo?.id
        };

        console.log("params", requestedPayload);

        const { success, message } = await apiService(
          'POST',
          '/approve-wallet-request',
          requestedPayload,
          null
        );

        console.log("get recharge res", success, message)
        if (success) {
          await updateAdminWalletBalance();
          await fetchPendingWalletReport();
          setIsModalOpen(false);
          setToastMessage({
            message: 'Approved Successfully!',
            toastType: 'success'
          })
        } else if (message === 'Failed to authenticate') {
          handleApiError('Failed to authenticate');
          setSessionExpired(true);
        } else {
          handleApiError(message);
        }
      } catch (error) {
        console.error('Failed to fetch matches:', error);
        handleApiError(error);
      } finally {
        setIsApproveLoading(false); // Hide loader after data is fetched or an error occurs
      }
    } else {
      handleApiError("Requested runs are more that your wallet balance. Please recharge your wallet and try again.");
    }
  }


  // Changing state value when searching name
  useEffect(() => {
    fetchPendingWalletReport(); // Fetch data when component mounts or dependencies change
  }, [currentPage, itemPerPage, sort, onSearchText]);

  return (
    <React.Fragment>
      <Head title="Trasaction List - Crypto"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Pending Wallet Requests</BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {totalRows} pending requests.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner">
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">All Orders</h5>
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <Button
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </Button>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                          <Icon name="setting"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end className="dropdown-menu-xs">
                          <ul className="link-check">
                            <li>
                              <span>Show</span>
                            </li>

                            <li className={itemPerPage === 10 ? "active" : ""}>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setItemPerPage(10);
                                }}
                              >
                                10
                              </DropdownItem>
                            </li>
                            <li className={itemPerPage === 15 ? "active" : ""}>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setItemPerPage(15);
                                }}
                              >
                                15
                              </DropdownItem>
                            </li>
                          </ul>
                          <ul className="link-check">
                            <li>
                              <span>Order</span>
                            </li>
                            <li className={sort === "dsc" ? "active" : ""}>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortState("dsc");
                                  sortFunc("dsc");
                                }}
                              >
                                DESC
                              </DropdownItem>
                            </li>
                            <li className={sort === "asc" ? "active" : ""}>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setSortState("asc");
                                  sortFunc("asc");
                                }}
                              >
                                ASC
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="search-content">
                    <Button
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                      className="search-back btn-icon toggle-search"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by Order Id"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody bodyclass="nk-tb-tnx">
              <DataTableHead>
                <DataTableRow>
                  <span>Request Customer</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Order ID</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Number of Runs</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Amount</span>
                </DataTableRow>
                <DataTableRow>
                  <span>Date</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-status">
                  <span className="sub-text d-none d-md-block">Status</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools"></DataTableRow>
              </DataTableHead>

              {isWalletReportLoading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <Spinner
                      color="primary"
                      type="grow"
                    >
                      Loading...
                    </Spinner>
                  </td>
                </tr>
              ) : (
                data.length > 0
                  ? data.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow>
                          <div className="nk-tnx-type">
                            <div
                              className={`nk-tnx-type-icon bg-${item.order_status === "completed"
                                ? "success-dim text-success"
                                : item.order_status === "pending"
                                  ? "warning-dim text-warning"
                                  : item.order_status === "upcoming"
                                    ? "info-dim text-info"
                                    : "danger-dim text-danger"
                                }`}
                            >
                              <Icon name="arrow-up-right"></Icon>
                            </div>
                            <div className="nk-tnx-type-text">
                              <span className="tb-lead">{`${item?.name} ${item?.lname}`}</span>
                              <span className="tb-date">{item?.email}</span>
                            </div>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="tb-amount">
                            #{item.id}
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="tb-amount">
                            {item.no_runs} <span>Runs</span>
                          </span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                          <span className="tb-amount">
                            <span>$ </span>{item.amount}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                          <span className="tb-amount">
                            {item.createdOn}
                          </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-status">
                          <div
                            className={`dot dot-${item.order_status === "Completed"
                              ? "success"
                              : item.order_status === "pending"
                                ? "warning"
                                : item.order_status === "success"
                                  ? "info"
                                  : "danger"
                              } d-md-none`}
                          ></div>
                          <Badge
                            className="badge-sm badge-dim d-none d-md-inline-flex"
                            color={`outline-${item.order_status === "Completed"
                              ? "success"
                              : item.order_status === "pending"
                                ? "warning"
                                : item.order_status === "success"
                                  ? "info"
                                  : "danger"
                              }`}
                          >
                            {item.order_status}
                          </Badge>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <React.Fragment>
                              <li className="nk-tb-action-hidden" onClick={() => onApproveClick(item)}>
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="bg-white btn btn-sm btn-outline-light btn-icon btn-tooltip"
                                  id={item.ref + "approve"}
                                  icon="done"
                                  direction="top"
                                  text="approve"
                                />
                              </li>
                              {/* <li className="nk-tb-action-hidden" onClick={() => onRejectClick(item.id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="bg-white btn btn-sm btn-outline-light btn-icon btn-tooltip"
                                    id={item.ref + "reject"}
                                    icon="cross-round"
                                    direction="top"
                                    text="Reject"
                                  />
                                </li> */}
                            </React.Fragment>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle bg-white btn btn-sm btn-outline-light btn-icon"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <React.Fragment>
                                      <li onClick={() => onApproveClick(item)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#approve"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="done"></Icon>
                                          <span>Approve</span>
                                        </DropdownItem>
                                      </li>
                                      {/* <li onClick={() => onRejectClick(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#reject"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="cross-round"></Icon>
                                            <span>Reject</span>
                                          </DropdownItem>
                                        </li> */}
                                    </React.Fragment>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                  : null)}
            </DataTableBody>
            <div className="card-inner">
              {data.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={totalRows}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) :
              !isWalletReportLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
            </div>
          </DataTable>
        </Block>

        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              className="close"
              onClick={() => setIsModalOpen(false)}
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Are you sure you want to approve {selectedRequest?.no_runs} runs request?</h5>
              <Col size="12 mt-5">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <a
                      href="#cancel"
                      className="link link-light"
                      onClick={() => setIsModalOpen(false)}
                    >
                      No
                    </a>
                  </li>
                  <li>
                    <Button onClick={handleApprove} color="primary" size="md">
                      {isApproveLoading ?
                        <Spinner size="sm" color="light" /> : 'Yes'}
                    </Button>
                  </li>
                </ul>
              </Col>
            </div>
          </ModalBody>
        </Modal>
      </Content>
      <CustomToast
        open={toastMessage?.toastType ? true : false}
        handleClose={handleClose}
        severity={toastMessage?.toastType}
        message={toastMessage?.message}
      />
    </React.Fragment>
  );
};

export default PendingWalletRequestList;
